
import { defineComponent, ref } from 'vue'
import ClassicEditor from '@/global-components/ckeditor/classic-editor'

export default defineComponent({
  setup () {
    const categories = 1
    const classicEditor = ClassicEditor
    const simpleEditorConfig = {
      removePlugins: [
        "Markdown",
        "MediaEmbedToolbar",
        "ImageToolbar"
      ],
      toolbar: {
        items: ['bold', 'italic', 'underline', 'link']
      }
    }
    const editorData = ref('<p>Content of the editor.</p>')

    return {
      categories,
      classicEditor,
      simpleEditorConfig,
      editorData
    }
  }
})
